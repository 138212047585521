import { Log, School } from '.';

export enum EStreamStatus {
  records = 'records',
  live = 'live'
}

export type AnswerReports = {
  id: string;
  isCorrect: boolean;
  learningExperienceName: string;
  roomId: string;
  stationNumber: string;
  userAnswer: string;
  userId: string;
  userName: string;
  timestamp: string;
};

export type ProgressReports = {
  id: string;
  lastCompletedStation: number;
  roomId: string;
  learningExperienceName: string;
  leTimeSpent: string;
  userId: string;
  userName: string;
  lastCompletionStationTimestamp: string;
  endAt: string;
  startAt: string;
  org: string;
  totalStationCount: number;
  timestampOfLastStationCompletion: string;
  timeSpentInLe: number;
  timestampOfStart: string;
  timestampOfEnd: string;
};

export type ChatReports = {
  id: string;
  learningExperienceName: string;
  roomId: string;
  whatWasSaid: string;
  userId: string;
  userName: string;
  timestamp: string;
  scriptedOrQA: string;
};

export type SessionVrUser = {
  id: string;
  firebaseId: string;
  firstName: string;
  lastName: string;
  email: string;
  logs: Log[];
  isGuest?: boolean;
};

export type Session = {
  roomId: string;
  duration: number;
  logId: string[];
  startedAt: string;
  sessionId: string;
  usersQuantity: number;
  users: SessionVrUser[];
  school: School;
  timezone: string;
  streamId?: string;
  streamUrl?: string;
  streamStatus?: EStreamStatus;
  InternalId?: string;
  answerReports?: AnswerReports[];
  progressReports?: ProgressReports[];
  chatReports?: ChatReports[];
};

export type TableSession = {
  key: string;
  id: string;
  roomId: string;
  startedAt: string;
  duration: string;
  users: SessionVrUser[];
  userName: string;
  school: string;
  streamUrl?: string;
  streamStatus?: EStreamStatus;
  InternalId?: string;
  answerReports?: AnswerReports[];
  progressReports?: ProgressReports[];
  chatReports?: ChatReports[];
};
