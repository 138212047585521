import { YOUTUBE_LINK_REGEXP } from 'pages/youtube-link-page/constants';
import { EAdminRoles } from 'shared/types';
import * as Yup from 'yup';

const FIELDS = {
  email: Yup.string().email('Please enter a valid e-mail address').required('Required field'),
  password: Yup.string()
    .min(8, 'Use 8 or more characters')
    .max(50, 'Only 50 symbols allowed')
    .required('Required field'),
  confirmPassword: Yup.string()
    .min(8, 'Use 8 or more characters')
    .max(50, 'Only 50 symbols allowed')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Passwords must match'),
  name: Yup.string().trim().required('Required field'),
  nameNotRequired: Yup.string().trim(),
  userIdRange: Yup.object().nullable(),
  firebaseId: Yup.string()
    .required('Required field')
    .test('validId', 'Must be exactly 4 digits', (value) => /^\d{4}$/.test(value || '')),
  role: Yup.string().required('Required field'),
  school: Yup.string().when('role', {
    is: (role: EAdminRoles): boolean => role === EAdminRoles.SCHOOL_ADMIN,
    then: Yup.string().required('Required field')
  }),
  code: Yup.string()
    .min(4, 'Use 4 or more characters')
    .max(10, 'Only 10 symbols allowed')
    .required('Required field'),
  youtubeName: Yup.string().trim().required('Required field'),
  youtubeLink: Yup.string()
    .trim()
    .required('Required field')
    .matches(YOUTUBE_LINK_REGEXP, 'Link must be a valid YouTube URL')
};

export const validation = {
  LOG_IN: Yup.object().shape({
    email: FIELDS.email,
    password: FIELDS.password
  }),
  ADD_SCHOOL: Yup.object().shape({
    Name: FIELDS.name,
    Domain: Yup.array()
      .transform((value) => (typeof value === 'string' ? value.split(/ *, */) : value))
      .min(1, 'Required field')
      .nullable(),
    Abbreviation: FIELDS.name
  }),
  UPLOAD_SCHOOL_NAME: Yup.object().shape({
    name: FIELDS.name
  }),
  CREATE_ADMIN: Yup.object().shape({
    email: FIELDS.email,
    password: FIELDS.password,
    firstName: FIELDS.name,
    lastName: FIELDS.name,
    school: FIELDS.school,
    role: FIELDS.role
  }),
  EDIT_ADMIN: Yup.object().shape({
    email: FIELDS.email,
    firstName: FIELDS.name,
    lastName: FIELDS.name,
    school: FIELDS.school,
    role: FIELDS.role
  }),
  RESET_PASSWORD: Yup.object().shape({
    email: FIELDS.email
  }),
  CHANGE_ADMIN_PASSWORD: Yup.object().shape({
    password: FIELDS.password,
    confirmPassword: FIELDS.confirmPassword
  }),
  CREATE_VR_USER: Yup.object().shape({
    FirstName: FIELDS.nameNotRequired,
    LastName: FIELDS.nameNotRequired,
    emailName: FIELDS.name,
    Email: FIELDS.email,
    Code: FIELDS.firebaseId
  }),
  UPDATE_VR_USER: Yup.object().shape({
    FirstName: FIELDS.nameNotRequired,
    LastName: FIELDS.nameNotRequired,
    emailName: FIELDS.name,
    Email: FIELDS.email,
    Code: FIELDS.firebaseId
  }),
  CREATE_YOUTUBE_LINK: Yup.object().shape({
    name: FIELDS.youtubeName,
    link: FIELDS.youtubeLink
  })
};
