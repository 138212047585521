import { School, TableLog } from '.';

export type VrUser = {
  id: string;
  ID: string;
  firebaseId: string;
  FirstName: string;
  LastName: string;
  Email: string;
  isGuest?: boolean;
  IsGuest?: boolean;
  vreduNumber: string;
  OrganizationId: string;
  Code: string;
};

export type VrUserInternal = {
  id: string;
  ID: string;
  firebaseId?: string;
  Code: string;
  firstName: string;
  lastName: string;
  email: string;
  isGuest?: boolean;
  IsGuest?: boolean;
  vreduNumber?: string;
  school: any;
  logs: TableLog[];
};

export type UsersState = {
  allUsers: VrUserInternal[];
  users: VrUser[];
  totalPages: number;
  totalSchools: number;
  currentPage: number;
  isSchoolsLoading: boolean;
  sortBy: string;
};

export type CreatedVrUser = {
  firebaseId?: string;
  FirstName: string;
  LastName: string;
  Email: string;
  OrganizationId?: string;
  Code: string;
};

export type UpdatedUser = {
  profile: {
    FirstName: string;
    LastName: string;
    Email: string;
    vreduNumber: string;
  };
  school: School;
};

export type UpdatedVrUser = {
  FirstName: string;
  LastName: string;
  Email: string;
  Code: string;
};

export type TableVrUser = {
  key: string;
  ID: string;
  firebaseId?: string;
  FirstName: string;
  LastName: string;
  Email: string;
  emailName: string;
  OrganizationId: string;
  Code: string;
};

export type TableReportVrUser = {
  key: string;
  ID: string;
  Code?: string;
  firebaseId?: string;
  FirstName: string;
  LastName: string;
  Email: string;
  LastActive: string | number;
  FirstSession: string;
  OrganizationId: string;
  OrganizationName: string;
};

export enum EVrUserField {
  firstName = 'firstName',
  lastName = 'lastName'
}
