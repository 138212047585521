import { LogUser, SessionVrUser } from 'shared/types';

export const getUser = (item: LogUser | SessionVrUser): string => {
  switch (true) {
    case !!item.firstName && !!item.lastName:
      return `${item.lastName}, ${item.firstName}`;
    case !!item.firstName:
      return `${item.firstName}`;
    case !!item.lastName:
      return `${item.lastName}`;
    case !!item.isGuest:
      return 'GUEST, GUEST';
    default:
      return '';
  }
};
