import { AxiosResponse } from 'axios';
import ApiService from './api';
import { Session, QueryParams, AnswerReports, ProgressReports, ChatReports } from 'shared/types';
import { secondsTransform } from 'utils/date-utils';

export const getSessions = async (
  page: number,
  limit: number,
  orderBy: { orderBy?: string },
  queryParams: QueryParams
): Promise<AxiosResponse<Session[]>> =>
  await ApiService.get<Session[]>('sessions', { page, limit, ...orderBy, ...queryParams });

export const deleteSession = async (sessionId: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete<void>(`sessions/${sessionId}`);

export const downloadAnswerReports = async (sessionId: string, reports: AnswerReports[]) => {
  try {
    let csvData =
      [
        'Student name',
        'Room',
        'LE Name',
        'Station',
        'Answer entered',
        'Correct?',
        'UTC Timestamp'
      ].join(',') + '\r\n';

    reports.forEach((report) => {
      csvData +=
        [
          report.userName,
          report.roomId,
          report.learningExperienceName,
          report.stationNumber,
          `"${report.userAnswer}"`,
          report.isCorrect ? 'Correct' : 'Incorrect',
          report.timestamp
        ].join(',') + '\r\n';
    });
    const BOM = '\uFEFF'; // UTF-8 Byte Order Mark
    const csvContent = BOM + csvData; // Prepend BOM to CSV data
    const blob = new Blob([csvContent]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `answers-reports_${sessionId}.csv`;
    link.click();
  } catch (error) {
    console.error(error);
  }
};

export const downloadProgressReports = async (sessionId: string, reports: ProgressReports[]) => {
  try {
    let csvData =
      [
        'Student name',
        'Org',
        'Room',
        'LE Name',
        'Last Station completed',
        'UTC Timestamp of last station completion',
        'Time Spent In LE',
        'UTC Timestamp of Start/enter',
        'UTC Timestamp of End/exit'
      ].join(',') + '\r\n';

    reports.forEach((report) => {
      csvData +=
        [
          report.userName,
          report.org,
          report.roomId,
          report.learningExperienceName,
          !!report?.lastCompletedStation && report.lastCompletedStation > 0
            ? report.lastCompletedStation
            : 'Intro Station',
          report.timestampOfLastStationCompletion,
          secondsTransform(report.timeSpentInLe),
          report.timestampOfStart,
          report.timestampOfEnd
        ].join(',') + '\r\n';
    });
    const BOM = '\uFEFF'; // UTF-8 Byte Order Mark
    const csvContent = BOM + csvData; // Prepend BOM to CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `progress-reports_${sessionId}.csv`;
    link.click();
  } catch (error) {
    console.error(error);
  }
};

export const downloadChatReports = async (sessionId: string, reports: ChatReports[]) => {
  try {
    let csvData =
      ['Name', 'Room', 'LE Name', 'What was said', 'UTC Timestamp', 'Scripted or Q&A'].join(',') +
      '\r\n';

    reports.forEach((report) => {
      csvData +=
        [
          report.userName,
          report.roomId,
          `"${report.learningExperienceName}"`,
          `"${report.whatWasSaid}"`,
          report.timestamp,
          report.scriptedOrQA
        ].join(',') + '\r\n';
    });

    const BOM = '\uFEFF'; // UTF-8 Byte Order Mark
    const csvContent = BOM + csvData; // Prepend BOM to CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `chat-log-reports_${sessionId}.csv`;
    link.click();
  } catch (error) {
    console.error(error);
  }
};

export const downloadAllSessionProgressReports = async (): Promise<void> => {
  try {
    const response = await ApiService.get<Blob>(`progress-reports/export`);

    const contentDisposition = response.headers['content-disposition'];

    const matches = /filename="(.+)"/.exec(contentDisposition);
    const filename =
      matches && matches[1] ? matches[1] : `superadmin_progress_reports_${Date.now()}.csv`;

    const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error downloading the CSV file:', error);
    throw error;
  }
};
